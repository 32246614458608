import { Layout, Divider } from "antd";
import { ArrowDownOutlined } from '@ant-design/icons'
import { ExcelReader } from "../components/ExcelReader/";
import { useEffect } from "react";
import SelectWithModalWrapper from '../components/GatewayDicom/GatewayDicomSelectWrapper'
import _ from "lodash";
import JobWrapper from '../components/Jobs/JobWrapper'
import { useContext } from 'react'
import { AppContext } from '../../libs/context'
import { ExcelList } from './ExcelList'
import {
  CLOUD_PACS_MODE,
} from "../constants/dicomNode";

export const BatchTransfering = () => {
  const { store } = useContext(AppContext)
  const { selectedSourceGatewayId,
    setExcelRawData,
    clearAllCBKeys,
    setExcelTableData,
    setSelectedSeries,
    selectedSourceDicomNodeId,
    selectedDestinationGatewayId,
    setStudyWithSeriesData,
    clearAllStudyData,
    setImageSharingStudyData,
    clearExpandedRowKeys } = store

  const onExcelFileReadFinish = (excelFileData) => {
    // clear all excel raw data and table
    setExcelRawData([]);
    setExcelTableData([]);
    // clear all options
    clearAllCBKeys();
    clearExpandedRowKeys();
    clearAllStudyData();

    // clear study data and series
    setImageSharingStudyData({})
    setSelectedSeries([])
    setStudyWithSeriesData({});
    // set new raw data table
    setExcelRawData(excelFileData);
  };

  const { setOnboardingComplete } = store;
  const { currentSelectedSeries, hasCompletedOnboarding, selectedDestinationDicomId } = store;
  const selectSourceGatewayAndDicomNode = selectedSourceGatewayId && selectedSourceDicomNodeId;
  const isSelectedCloudPacs = selectedDestinationGatewayId === CLOUD_PACS_MODE;
  const selectedDestinationGatewayAndDicom = (selectedDestinationGatewayId && selectedDestinationDicomId) || isSelectedCloudPacs
  const isSelectedSeries = currentSelectedSeries.length > 0;
  const isSelectedAllGatewayDicomSourceAndDestination = selectedDestinationGatewayAndDicom && selectSourceGatewayAndDicomNode

  const shouldShowStudiesList = isSelectedAllGatewayDicomSourceAndDestination || hasCompletedOnboarding;
  const shouldShowJob = isSelectedSeries || hasCompletedOnboarding;

  useEffect(() => {
    const setUserOnboard = () => {
      if (isSelectedSeries && isSelectedAllGatewayDicomSourceAndDestination) {
        setOnboardingComplete();
      }
    }
    setUserOnboard();
  }, [isSelectedAllGatewayDicomSourceAndDestination, isSelectedSeries])

  return (
    <Layout className="bg-white flex-row flex-col w-full">
      <div className="p-4 mx-0 center mx-auto">
        <h1 className="text-2xl font-bold text-center">Batch Transfering</h1>
        <Divider className="border-gray-300" />
        <SelectWithModalWrapper />
        <Divider className="border-gray-300" />
        <div className="w-full py-3">
          <ExcelReader onExcelFileReadFinish={onExcelFileReadFinish} />
        </div>
        {shouldShowStudiesList &&
          <div className="w-full py-3">
            <div className="flex items-center justify-center h-full w-full">
              <ArrowDownOutlined style={{ fontSize: '24px' }} />
            </div>
            <ExcelList />
          </div>}
        {shouldShowJob &&
          <>
            <Divider className="border-gray-300" />
            <JobWrapper />
          </>
        }

      </div>
    </Layout >
  );
};
