import _ from 'lodash';

export const QueueJobs = (set, get) => ({
    queueJobs: [],
    setJobs: (jobs) => set({ queueJobs: jobs }),
    // 
    pendingQueueJobs: [],
    setPendingJobs: (jobs) => set({ pendingQueueJobs: jobs })
});

export const JobStore = (set, get) => ({
    runningJobs: [],
    jobStatusMap: {},
    selectedJobs: new Set(),
    cacheAllJobs: {},
    addJobToCache: (job) => set((state) => ({
        cacheAllJobs: { ...state.cacheAllJobs, [job.id]: job }
    })),
    getJobById: (jobId) => {
        const state = get();
        return state.cacheAllJobs[jobId] || null;
    },
    removeJobFromCache: (jobId) => set((state) => {
        const { [jobId]: _, ...rest } = state.cacheAllJobs;
        return { cacheAllJobs: rest };
    }),
    updateJobInCache: (updatedJob) => set((state) => ({
        cacheAllJobs: { ...state.cacheAllJobs, [updatedJob.id]: updatedJob }
    })),
    addJob: async (jobResponse) => {
        set((state) => ({
            runningJobs: [...state.runningJobs, ...jobResponse] // Adjust according to how jobResponse.data is structured
        }));
    },
    getLatestPendingJob: () => {
        return get().pendingQueueJobs;
    },
    cancelLocalJobs: (ids) => set((state) => ({
        pendingQueueJobs: state.pendingQueueJobs.filter(job => !ids.includes(job.id))
    })),
    removeJob: (jobId) => set((state) => {
        // Remove from runningJobs
        const filteredJobs = state.runningJobs.filter(job => job.id !== jobId);

        // Remove from cache
        const { [jobId]: _, ...remainingCache } = state.cacheAllJobs;

        return {
            runningJobs: filteredJobs,
            cacheAllJobs: remainingCache
        };
    }),
    updateRunningJobs: (updates) => set((state) => {
        if (updates.length > 0) {
            const updatedJobs = _.unionBy(
                state.runningJobs.map((job) => {
                    const update = updates.find((u) => u.id === job.id);
                    return update ? { ...job, status: update.status, name: update.name } : job;
                }),
                updates,
                "id"
            );

            // Update status map
            const newStatusMap = { ...state.jobStatusMap };
            updates.forEach(update => {
                newStatusMap[update.id] = update.status;
            });

            return {
                runningJobs: updatedJobs,
                jobStatusMap: newStatusMap
            };
        }
    }),
    addSelectedJob: (jobId) => {
        set((state) => {
            const newSet = new Set(state.selectedJobs);
            newSet.add(jobId);
            return { selectedJobs: newSet };
        });
    },
    removeSelectedJob: (jobId) => {
        set((state) => {
            const newSet = new Set(state.selectedJobs);
            newSet.delete(jobId);
            return { selectedJobs: newSet };
        });
    },
    clearAllSelectedJobs: () => {
        set({ selectedJobs: new Set() });
    }
})