import React from 'react';
import SelectWithModal from './GatewayDicom'
import { ArrowRightOutlined } from '@ant-design/icons'
import { SourceGatewayDicomNodeSelection } from "./SourceGatewayFilter";
import { DestinationGateway } from "./DestinationGatewayFilter";
import { DestinationGatewayCloudPacs } from "./CloudPacs";
import { AppContext } from '../../../libs/context';
import { useContext } from "react";
import { Divider } from 'antd';
import clx from 'classnames'
import { MODAL_SHOW_GATEWAY_DICOM_MANAGE } from '../../../../src/pages/constants/modals';
import {
    CLOUD_PACS_MODE,
    LISTENER_ONLINE_STATUS,
} from "../../../pages/constants/dicomNode";

const CloudPacs = {
    id: CLOUD_PACS_MODE,
    name: "Cloud PACS System",
    status: LISTENER_ONLINE_STATUS,
    listener_ip: "N/A",
    listener_status: "N/A",
    gateway_ip: "N/A",
    type: 'N/A'
};


const SelectWithModalWrapper = () => {
    const { store, setCurrentModal } = useContext(AppContext)
    const { selectedSourceGatewayId,
        gatewayData,
        selectedDestinationGatewayId,
        destinationGateways,
        destinationDicomNodes,
        sourceDicomNodes,
        selectedDestinationDicomId,
        selectedSourceDicomNodeId } = store

    const selectedDestinationGatewayAndDicom = selectedDestinationGatewayId && selectedDestinationDicomId;
    const selectSourceGatewayAndDicomNode = selectedSourceGatewayId && selectedSourceDicomNodeId;
    const isSelectedCloudPacs = selectedDestinationGatewayId === CLOUD_PACS_MODE;

    const showDicomAndGatewaySelectionModal = (type) => {
        setCurrentModal({
            modal: MODAL_SHOW_GATEWAY_DICOM_MANAGE,
            data: {
                type
            },
        });
    }

    return (
        <div className='w-full h-full'>
            <div className="flex flex-row w-full">
                <div className="w-1/2 mr-2.5">
                    <div className="flex items-center justify-center">
                        {!selectSourceGatewayAndDicomNode &&
                            <SelectWithModal
                                label={"From : "}
                                gatewayData={gatewayData}
                                onSelectClick={() => showDicomAndGatewaySelectionModal('source')}
                                dicomNodes={sourceDicomNodes}
                            />}

                        {selectSourceGatewayAndDicomNode &&
                            <SourceGatewayDicomNodeSelection
                                label={"From : "}
                                gatewayData={gatewayData}
                                dicomNode={sourceDicomNodes}
                                selectedSourceDicomNodeId={selectedSourceDicomNodeId}
                                currentSelectedGatewayId={selectedSourceGatewayId}
                                onShowMoreClick={() => showDicomAndGatewaySelectionModal('source')}
                            />}
                    </div>

                </div>
                <div className="mr-2.5 items-center justify-center">
                    <Divider className={clx("ml-5 border-gray-400", {
                        "h-[20px]": !selectedDestinationGatewayAndDicom && !selectSourceGatewayAndDicomNode,
                        "h-[100px]": selectedDestinationGatewayAndDicom || selectSourceGatewayAndDicomNode
                    })} type='vertical'></Divider>
                    <div className='w-full mt-2.5 mb-2.5'>
                        <ArrowRightOutlined className="text-2xl ml-2.5" />
                    </div>
                    <Divider className={clx("ml-5 border-gray-400", {
                        "h-[20px]": !selectedDestinationGatewayAndDicom && !selectSourceGatewayAndDicomNode,
                        "h-2/3": selectedDestinationGatewayAndDicom || selectSourceGatewayAndDicomNode
                    })} type='vertical'></Divider>
                </div>
                <div className="w-1/2 ml-3.5">
                    {!selectedDestinationGatewayAndDicom && !isSelectedCloudPacs &&
                        <SelectWithModal
                            label={"To : "}
                            dicomNodes={destinationDicomNodes}
                            onSelectClick={() => showDicomAndGatewaySelectionModal('destination')}
                        />
                    }

                    {isSelectedCloudPacs && 
                        <DestinationGatewayCloudPacs 
                            label={"To : "} 
                            gatewayData={[CloudPacs]} 
                            onShowMoreClick={() => showDicomAndGatewaySelectionModal('destination')}
                            selectedDestinationGatewayId={selectedDestinationGatewayId} 
                        />
                    }
                    
                    {selectedDestinationGatewayAndDicom &&
                        <DestinationGateway
                            label={"To : "}
                            selectedDestinationGatewayId={selectedDestinationGatewayId} // destination gateway
                            selectedDestinationDicomNodeId={selectedDestinationDicomId} // destination dicom node
                            dicomNode={destinationDicomNodes}
                            gatewayData={[CloudPacs, ...destinationGateways]}
                            onShowMoreClick={() => showDicomAndGatewaySelectionModal('destination')}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default SelectWithModalWrapper;
