export const DicomStore = (set) => ({
  sourceDicomNodes: null,
  destinationDicomNodes: null,
  selectedDestinationDicomId: null,
  setDestinationDicome: (selectedDestinationDicomId) => set({ selectedDestinationDicomId }),
  selectedSourceDicomNodeId: null,
  setSourceDicomNodes: (node) => set({ sourceDicomNodes: node }),
  setDestinationDicomNodes: (node) => set({ destinationDicomNodes: node }),
  setSourceDicomeNodeID: (id) => set({ selectedSourceDicomNodeId: id }),
  // 
  selectedSourceDicomNodeIdTemp: null,
  selectedDestinationDicomIdTemp: null,
  setSourceDicomeNodeIDTemp: (id) => set({ selectedSourceDicomNodeIdTemp: id }),
  setDestinationDicomeTemp: (selectedDestinationDicomIdTemp) => set({ selectedDestinationDicomIdTemp }),
})